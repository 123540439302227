<template>
  <a-layout-content>
    <page-header>
      <template #title> Onboarding Approvals </template>
    </page-header>

    <a-card
      size="small"
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="onTabChange"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.count }})
      </template>
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id && index"
        :dataSource="tableData"
        :loading="loading"
        :pagination="false"
        size="small"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #attachment="{ record }">
          <template v-for="(item, idx) in record.attachments" :key="item.id">
            <a-typography-link :href="item.url" target="_blank">
              {{ item.file_name_with_ext }}
            </a-typography-link>
            <br v-if="idx !== record.attachments.length - 1" />
          </template>
        </template>

        <template #social_security_attachment="{ record }">
          <a-typography-link
            v-if="record.attachment"
            :href="record.attachment_url"
            target="_blank"
          >
            {{ record.attachment }}
          </a-typography-link>
          <span v-else>N/A</span>
        </template>

        <template #document_number="{ record }">
          <a-space>
            {{ record.document_number }}
            <a-popover v-if="record.type === 'DL'">
              <template #content>
                <div style="max-width: 300px">
                  <span style="font-weight: 500">License expiry date:</span>
                  {{ this.$customDate.dmy(record.license_expiry_date) }}
                  <br />
                  <span style="font-weight: 500">License state:</span>
                  {{ record.license_state }}
                </div>
              </template>
              <a-button size="small" type="link">
                <i class="ti ti-info-circle ti-lg"> </i>
              </a-button>
            </a-popover>
          </a-space>
        </template>
        <template #action="{ record }">
          <a-space :size="1">
            <a-tooltip title="Edit">
              <a-button
                v-if="activeTabKey === 'credentialing'"
                type="link"
                @click="editCredential(record)"
              >
                <template #icon>
                  <i class="ti ti-pencil" style="font-size: 18px"></i>
                </template>
              </a-button>
            </a-tooltip>
            <a-popconfirm
              title="Are you sure you want to approve this credential?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="() => approveCredential(record, true)"
            >
              <a-tooltip title="Approve">
                <a-button
                  type="link"
                  :loading="record.approveLoading"
                  :disabled="record.approveDisabled"
                >
                  <template #icon>
                    <i class="ti ti-checkbox" style="font-size: 18px"></i>
                  </template>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
            <a-tooltip title="Reject">
              <a-button
                type="link"
                :loading="record.rejectLoading"
                :disabled="record.rejectDisabled"
                style="color: #d4380d"
                @click="approveCredential(record, false)"
              >
                <template #icon>
                  <i class="ti ti-circle-x" style="font-size: 18px"></i>
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import { constants } from "../../../helper/constants";
import { commonService } from "../../../services";
import xFilterIcon from "../../../components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "../../../components/table/filterInputSearchDropdown.vue";

export default {
  components: {
    xFilterIcon,
    xFilterInputSearchDropdown,
  },
  data() {
    return {
      tableData: [],
      activeTabKey: "credentialing",
      credentialingData: [],
      ssn_dlData: [],
      loading: false,
      credentialingColumns: [
        {
          title: "Employee",
          dataIndex: "employee.full_name",
        },
        {
          title: "Certificate",
          dataIndex: "certification_type.name",
        },
        {
          title: "Expires On",
          dataIndex: "expiry_date",
          customRender: ({ text }) => {
            return this.$customDate.ll(text);
          },
        },
        {
          title: "Attachment",
          slots: {
            customRender: "attachment",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      ssn_dlColumns: [
        {
          title: "Employee",
          dataIndex: "full_name",
        },
        {
          title: "Type",
          dataIndex: "type",
        },
        {
          title: "Document Number",
          dataIndex: "document_number",
          slots: {
            customRender: "document_number",
          },
        },
        {
          title: "Attachment",
          slots: {
            customRender: "social_security_attachment",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      tabList: this.$constants.onboardingStatuses.map((item) => ({
        key: item.key,
        text: item.tab,
        count: 0,
        slots: { tab: "customRender" },
      })),
    };
  },
  computed: {
    columns() {
      return this.activeTabKey === "credentialing"
        ? this.credentialingColumns
        : this.ssn_dlColumns;
    },
  },
  methods: {
    ...mapActions("drawer", ["open"]),

    async fetchData() {
      this.loading = true;
      try {
        const response = await commonService.get(
          constants.credentialApprovalsDatatableUrl
        );
        this.credentialingData = response.data.credentialing;
        this.ssn_dlData = response.data.ssn_dl;
        this.tabList[0].count = this.credentialingData.length;
        this.tabList[1].count = this.ssn_dlData.length;
        this.updateTableData();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },

    editCredential(record) {
      this.open({
        title: "Edit Credential",
        path: "admin.approvals.edit",
        callback: this.fetchData,
        record,
      });
    },

    approveCredential(record, isApprove) {
      if (isApprove) {
        record.approveLoading = true;
        record.rejectDisabled = true;
      } else {
        if (this.activeTabKey === "credentialing") {
          this.open({
            title: "Credential Rejection Remarks",
            path: "admin.approvals.reject",
            callback: this.fetchData,
            record,
          });
        } else {
          this.open({
            title: "SSN/DL Rejection Remarks",
            path: "admin.approvals.reject_ssn_dl",
            callback: this.fetchData,
            record,
          });
        }
        return;
      }
      if (this.activeTabKey === "credentialing") {
        commonService
          .store(this.$constants.credentialApprovalsApprovalUrl, {
            id: record.id,
            approval_status: 1,
          })
          .then((res) => {
            this.$message.success(res.message);
            this.fetchData();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            record.approveLoading = false;
            record.rejectLoading = false;
            record.approveDisabled = false;
            record.rejectDisabled = false;
          });
      } else {
        commonService
          .store(this.$constants.credentialApprovalsssndlApprovalUrl, {
            employee_id: record.id,
            type: record.type,
            approval_status: 1,
          })
          .then((res) => {
            this.$message.success(res.message);
            this.fetchData();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            record.approveLoading = false;
            record.rejectLoading = false;
            record.approveDisabled = false;
            record.rejectDisabled = false;
          });
      }
    },

    onTabChange(key) {
      this.activeTabKey = key;
      this.updateTableData();
    },
    updateTableData() {
      if (this.activeTabKey === "credentialing") {
        this.tableData = this.credentialingData;
      } else {
        this.tableData = this.ssn_dlData;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
