<template>
  <a-card :loading="loading" size="small"  :tab-list="tabs" :active-tab-key="activeTab"   @tabChange="onTabClick" class="employee-certificates">
    <template #title> Documents</template>

    <template #extra>
      <a-space v-if="!tableLoading">
        <a-button
          v-if="selectedRowKeys.length > 0"
          type="primary"
          size="small"
          :loading="downloadingZipFile"
          @click="handleDownloadZipFileClickEvent"
        >
          <a-space>
            <i class="ti ti-file-zip ti-lg"></i>
            ZIP
          </a-space>
        </a-button>

        <a-button
          type="primary"
          size="small"
          @click="handleNewDocumentRequestEvent"
        >
          <a-space>
            <i class="ti ti-plus ti-lg"></i>
            New Document Request
          </a-space>
        </a-button>

        <a-button
          type="primary"
          size="small"
          @click="handleAddDocumentClickEvent"
        >
          <a-space>
            <i class="ti ti-plus ti-lg"></i>
            Add New
          </a-space>
        </a-button>
      </a-space>
    </template>

    <a-table
      :row-selection="rowSelection"
      :loading="tableLoading"
      :columns="computedColumns"
      :data-source="filteredData"
      size="small"
      :rowKey="(record, index) => record.id"
      :pagination="false"
    >
      <template #file="{ record: attach }">
        <a :href="attach.full_file_path" target="_blank" download="test.pdf">
          {{ attach.file_name }}
        </a>
      </template>

      <template #action="{ record }">
        <!--        <a-tooltip title="Download">
          <a-button
            size="small"
            type="link"
            @click="downloadAttachment(record)"
          >
            <i class="ti ti-download ti-lg"></i>
          </a-button>
        </a-tooltip>-->

        <a-tooltip title="Edit">
          <a-button
            size="small"
            type="link"
            @click="handleEditDocumentClickEvent(record)"
          >
            <i class="ti ti-pencil ti-lg"></i>
          </a-button>
        </a-tooltip>

        <a-popconfirm
          v-if="record.status === 1"
          title="Are you sure you want to delete this document?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteAttachment(record.id)"
        >
          <a-tooltip title="Delete">
            <a-button size="small" type="link" danger>
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-tooltip>
        </a-popconfirm>

        <a-popconfirm
          v-else
          title="Are you sure you want to restore this document?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteAttachment(record.id)"
        >
          <a-tooltip title="Restore">
            <a-button size="small" type="link">
              <i class="ti ti-reload ti-lg"></i>
            </a-button>
          </a-tooltip>
        </a-popconfirm>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { commonService } from "../../../services";

export default {
  name: "employee-certificates",
  props: {
    employee: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      moment,
      tableLoading: true,
      downloadingZipFile: false,
      downloadingPdfFile: false,
      certificationTypes: [],
      data: [],
      columns: [
        {
          title: "Document",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "File",
          dataIndex: "file_path",
          key: "file",
          slots: {
            customRender: "file",
          },
        },
        {
          title: "Uploaded At",
          dataIndex: "created_at",
          key: "created_at",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: {
            customRender: "action",
          },
        },
      ],
      selectedRowKeys: [],
      activeTab: "active",
    };
  },

  computed: {

    computedColumns() {
    return this.columns.filter(
      (column) => !(this.activeTab === "archived" && column.key === "action")
    );
  },

    loading() {
      return this.employee == null;
    },

    hasFormI9orW9() {
      const holder = [];
      this.data.forEach((item) => {
        if (item.title === "Form I-9" || item.title === "Form W-9") {
          holder.push(item.title);
        }
      });

      return holder;
    },

    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys) => (this.selectedRowKeys = selectedRowKeys),
      };
    }, 

      activeArchivedDataSplit() {
        return {
          active: this.data.filter((item) => item.status === 1),
          inactive: this.data.filter((item) => item.status === 0),
          archived: this.data.filter((item) => item.status === 2),
        };
      },

      tabs() {
        const activeCount = this.activeArchivedDataSplit.active.length;
        const inactiveCount = this.activeArchivedDataSplit.inactive.length;
        const archivedCount = this.activeArchivedDataSplit.archived.length;

        return [
          {
            tab: `Active (${activeCount})`,
            key: "active",
          },
          {
            tab: `Inactive (${inactiveCount})`,
            key: "inactive",
          },
          {
            tab: `Archived (${archivedCount})`,
            key: "archived",
          },
        ];
      },

      filteredData() {
        const split = this.activeArchivedDataSplit;
        if (this.activeTab === "active") return split.active;
        if (this.activeTab === "inactive") return split.inactive;
        if (this.activeTab === "archived") return split.archived;
        return [];
      },
  },

  mounted() {
    this.getEmployeeDocuments();
    this.$comman.get_certification_types().then((res) => {
      this.certificationTypes = res;
    });
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    onTabClick(key) {
      this.activeTab = key;
      this.selectedRowKeys = []
    },

    getEmployeeDocuments() {
      commonService
        .get(this.$constants.employeeDocuments, {
          employee_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.success) {
            this.data = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.tableLoading = false));
    },

    handleAddDocumentClickEvent() {
      this.open({
        title: `Add New Document`,
        path: `employee.drawerForm.addDocument`,
        extra: {
          employee_id: this.$route.params.id,
          formI9W9: this.hasFormI9orW9,
        },
        callback: this.getEmployeeDocuments,
      });
    },

    handleNewDocumentRequestEvent() {
      this.open({
        title: `New Document Request`,
        path: `employee.drawerForm.newDocumentRequest`,
        extra: {
          employee_id: this.$route.params.id,
        },
        callback: this.getEmployeeDocuments,
      });
    },

    handleEditDocumentClickEvent(record) {
      this.open({
        title: `Edit Document`,
        path: `employee.drawerForm.editDocument`,
        record,
        extra: {
          employee_id: this.$route.params.id,
          formI9W9: this.hasFormI9orW9,
        },
        callback: this.getEmployeeDocuments,
      });
    },

    deleteAttachment(id) {
      this.tableLoading = true;
      commonService
        .get(this.$constants.employeeDocumentDelete, { id })
        .then(async (res) => {
          await this.getEmployeeDocuments();
          this.$message.success(res.message);
        })
        .catch((err) => {
          console.log(err);
          his.$message.error(err);
        })
        .finally(() => (this.tableLoading = false));
    },

    handleDownloadZipFileClickEvent() {
      this.downloadingZipFile = true;
      commonService
        .downloadFile(
          this.$constants.employeeDownloadDocumentsZipUrl,
          { id: this.employee.id, document_ids: this.selectedRowKeys },
          `${this.employee.first_name}-${
            this.employee.last_name
          }-credentialing-${this.$customDate.mdy(moment())}.zip`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.downloadingZipFile = false));
    },
  },

  watch: {
    "$route.params.id": function (val) {
      if (val) {
        this.getEmployeeDocuments();
      }
    },
  },
};
</script>
