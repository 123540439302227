<template>
  <a-layout-content>
    <page-header>
      <template #title> Employee Time Tracking </template>
    </page-header>

    <a-space direction="vertical" style="width: 100%">
      <a-card size="small">
        <a-space>
          <a-range-picker
            :allowClear="false"
            v-model:value="date_range"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            inputReadOnly
            :ranges="ranges"
          />
          <a-select
            v-model:value="hospital_id"
            show-search
            optionFilterProp="label"
            allowClear
            style="width: 200px"
            placeholder="Select hospital"
          >
            <a-select-option
              v-for="option in hospitals"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
          <a-select
            v-model:value="employee_id"
            show-search
            optionFilterProp="label"
            allowClear
            style="width: 200px"
            placeholder="Select employee"
          >
            <a-select-option
              v-for="option in employees"
              :key="option.id"
              :label="option.full_name"
              :value="option.id"
            >
              {{ option.full_name }}
            </a-select-option>
          </a-select>
          <a-button
            type="primary"
            :loading="employeeTimeTrackLoading"
            @click="getEmployeeTimeTrackSheet"
            :disabled="!date_range[0]"
          >
            <a-space>
              <i class="far fa-file-excel"></i>
              Download
            </a-space>
          </a-button>
        </a-space>
      </a-card>

      <a-card
        :tabList="tabList"
        :activeTabKey="activeTabKey"
        @tabChange="(key) => (activeTabKey = key)"
        size="small"
      >
        <template #customRender="item">
          {{ item.text }} ({{ item.counts }})
        </template>

        <a-table
          :columns="columns"
          :rowKey="(record, index) => record.id"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="datatableLoading"
          @change="handleTableChange"
          @refresh="refresh"
          size="small"
          :scroll="scroll"
        >
          <template #filterDropdown="filterDropdown">
            <xFilterInputSearchDropdown
              :filterDropdown="filterDropdown"
              @handleSearch="handleDatatableSearch"
              @handleReset="handleDatatableReset"
            />
          </template>

          <template #filterIcon="filterIcon">
            <xFilterIcon :filterIcon="filterIcon" />
          </template>

          <template #was_on_call="{ text }">
            <a-tag v-if="text == 0" color="red">No</a-tag>
            <a-tag v-else color="green">Yes</a-tag>
          </template>

          <template #action="{ record }">
            <a-tooltip title="View">
              <a-button
                type="link"
                size="small"
                class="pl-sm-0 primary-color"
                @click="handleShowTimeTrackingEvent(record)"
              >
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>
          </template>
        </a-table>
      </a-card>
    </a-space>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.managerTimesheetDatatableUrl,
      columns: [
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Employee",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_name
            ? [this.$route.query?.employee_name]
            : undefined,
        },
        {
          title: "Shift Type",
          dataIndex: "shift_type_name",
          key: "shift_type_name",
          customRender: ({ record }) => record.shift_type?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.shift_type_name
            ? [this.$route.query?.shift_type_name]
            : undefined,
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.start_date),
          defaultSortOrder: this.$route.query.sortField
            ? this.$route.query.sortField == "start_date"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.end_date),
          defaultSortOrder:
            this.$route.query.sortField == "end_date"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Total Hours",
          dataIndex: "total_time",
          key: "total_time",
          customRender: ({ record }) =>
            parseFloat(
              record.total_time +
                record.total_on_call_time +
                record.total_travel_time ?? 0
            ).toFixed(2),
        },
        {
          title: " On-call?",
          dataIndex: "was_on_call",
          key: "was_on_call",
          slots: { customRender: "was_on_call" },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          // fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.timeTrackingStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        employee_name: this.$route.query?.employee_name
          ? [this.$route.query?.employee_name]
          : undefined,
        shift_type_name: this.$route.query?.shift_type_name
          ? [this.$route.query?.shift_type_name]
          : undefined,
      },

      employeeTimeTrackLoading: false,
      date_range: [null, null],
      employee_id: undefined,
      employees: [],
      hospital_id: undefined,
      hospitals: [],
      ranges: {
        Today: [moment(), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
      },
    };
  },
  mounted() {
    this.getEmployees();
    this.getHospitals();
  },
  methods: {
    ...mapActions("drawer", ["open"]),

    getEmployees() {
      return commonService
        .get(this.$constants.getEmployeesUrl)
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => this.$message.error(err));
    },

    getHospitals() {
      return commonService
        .get(this.$constants.getHospitalsUrl)
        .then((res) => {
          this.hospitals = res.data;
        })
        .catch((error) => this.$message.error(error));
    },

    handleShowTimeTrackingEvent(record) {
      this.open({
        title: "Show Time Tracking Details",
        path: "manager.timesheet.show",
        callback: this.refresh,
        record: record,
      });
    },

    getEmployeeTimeTrackSheet() {
      let url = this.$constants.managerTimesheetDownloadExcelUrl;
      let dateString =
        this.date_range[0].clone().format("MMDDYY") +
        "-" +
        this.date_range[1].clone().format("MMDDYY");

      let fileName = "employee-payroll-time-tracking-" + dateString + ".xlsx";
      var params = {
        dates:
          this.$customDate.ymd(this.date_range[0]) +
          " - " +
          this.$customDate.ymd(this.date_range[1]),
          employee_id : this.employee_id,
          hospital_id: this.hospital_id
      };

      this.employeeTimeTrackLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.employeeTimeTrackLoading = false;
        });
    },
  },
};
</script>
