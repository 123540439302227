<template>
    <div>
      <a-form
        :model="formState"
        :rules="rules"
        layout="vertical"
        @finish="onSubmit"
      >
        <a-row :gutter="[20, 0]">
          <a-col :sm="24" :md="12">
            <a-form-item ref="first_name" label="First Name" name="first_name">
              <a-input
                v-model:value="formState.first_name"
                placeholder="Enter first name here"
                :disabled="true"
              />
            </a-form-item>
          </a-col>
  
          <a-col :sm="24" :md="12">
            <a-form-item ref="last_name" label="Last Name" name="last_name">
              <a-input
                v-model:value="formState.last_name"
                placeholder="Enter last name here"
                :disabled="true"
              />
            </a-form-item>
          </a-col>
  
          <a-col :sm="24" :md="12">
            <a-form-item
              ref="email"
              label="Email"
              name="email"
              :validateStatus="validate('email', 'status')"
              :help="validate('email', 'msg')"
            >
              <a-input
                v-model:value="formState.email"
                placeholder="Enter email here"
                :disabled="true"
              />
            </a-form-item>
          </a-col>
  
          <a-col :sm="24" :md="12">
            <a-form-item ref="contact_no" label="Contact No" name="contact_no">
              <a-input
                v-model:value="formState.contact_no"
                v-maska="$constants.contactNumberMask"
                :placeholder="$constants.contactNumberPlaceholder"
                :disabled="true"
              />
            </a-form-item>
          </a-col>
  
          <a-col :sm="24" :md="12">
            <a-form-item
              ref="employee_type"
              label="Profession Type"
              name="employee_type"
            >
              <a-select
                v-model:value="formState.employee_type"
                placeholder="select employee type"
                optionFilterProp="label"
              >
                <a-select-option
                  v-for="type in $constants.employee_types"
                  :key="type.value"
                  :label="type.text"
                  :value="type.value"
                >
                  {{ type.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :sm="24" :md="12">
            <a-form-item
              ref="employment_type"
              label="Employment Type"
              name="employment_type"
            >
              <a-select
                v-model:value="formState.employment_type"
                placeholder="select employment type"
                optionFilterProp="label"
              >
                <a-select-option
                  v-for="type in $constants.employment_types"
                  :key="type.value"
                  :label="type.text"
                  :value="type.value"
                >
                  {{ type.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :sm="24" :md="12">
            <a-form-item ref="company_id" label="Company" name="company_id">
              <a-select
                v-model:value="formState.company_id"
                placeholder="select company"
                optionFilterProp="label"
                showSearch
              >
                <a-select-option
                  v-for="company in companies"
                  :key="company.id"
                  :label="company.name"
                  :value="company.id"
                >
                  {{ company.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
  
          <a-col :sm="24" :md="12">
            <a-form-item
              ref="source"
              label="Source"
              name="source"
            >
              <a-select
                v-model:value="formState.source"
                placeholder="Select source..."
                optionFilterProp="label"
                 :disabled="true"
              >
                <a-select-option
                  v-for="source in filteredSources"
                  :key="source.value"
                  :label="source.label"
                  :value="source.value"
                > 
                  {{ source.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <side-drawer-buttons :name="submitButtonText" />
      </a-form>
    </div>
  </template>
  
  <script>
  import { companyService } from "@/services";
  import { mapActions, mapGetters } from "vuex";
  import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";
  
  export default {
    mixins: [formMixins],
  
    data() {
      return {
        formState: {
          first_name: null,
          last_name: null,
          email: null,
          contact_no: null,
          source : undefined,
          company_id: undefined,
          employee_type: undefined,
          employment_type: undefined,
        }, 
        rules: {
          first_name: {
            required: true,
            message: "This field is required!",
            transform: (value) => (value ? value.trim() : ""),
          },
          last_name: {
            required: true,
            message: "This field is required!",
            transform: (value) => (value ? value.trim() : ""),
          },
          email: {
            required: true,
            message: "This field is required!",
            type: "email",
            transform: (value) => (value ? value.trim() : ""),
          },
          contact_no: {
            required: true,
            message: "This field is required!",
            len: 12,
            transform: (value) => (value ? value.trim() : ""),
          },
          company_id: {
            required: true,
            message: "This field is required!",
            type: "number",
          },
          employee_type: {
            required: true,
            message: "This field is required!",
            type: "number",
          },
          source: {
            required: true,
            message: "This field is required!",
          },
          employment_type: {
            required: true,
            message: "This field is required!",
            type: "number",
          },
          // source: {
          //   required: true,
          //   message: "This field is required!",
          //   type: "number",
          // },
        },
      };
    },
    computed: {
      ...mapGetters("drawer", ["record", "extra"]),
  
      submitButtonText() {
        if (this.record.id) {
          return "Update";
        } else {
          return "Create";
        }
      },
  
      companies() {
        return this.extra.companies ?? {};
      },

      filteredSources() {
        let sources = [...this.$constants.sources]; 
        if (this.record.id) { 
        sources.push({ value: "Rehire", label: "Rehire" });
        }
        return sources;
    },
    },
  
    mounted() {
      this.getFormState();
    },
  
    methods: {
      ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
  
      getFormState() {
        this.formState = {
          first_name: this.record.first_name ?? null,
          last_name: this.record.last_name ?? null,
          email: this.record.email ?? null,
          contact_no: this.record.contact_no ?? null,
          company_id: this.record.company_id ?? undefined,
          source : this.record.source ?? 'Rehire',
          employee_type: this.record.employee_type ?? undefined,
          employment_type: this.record.employment_type ?? undefined,
        };
      },
  
      onSubmit() {
        let val = { ...this.formState };
        if (this.record.id) {
          val.id = this.record.id;
          val.employee_id = this.record.id
        }
  
        this.loadingStart();
        commonService
          .store(this.$constants.employeeRehire , val)
          .then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.close();
              this.$emit("callback");
            }
          })
          .catch((err) => {
              this.$message.error(err);
          })
          .finally(() => this.loadingStop());
      },
    },
  };
  </script>
  