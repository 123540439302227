<template>
  <a-layout-content>
    <page-header>
      <template #title> Case Report </template>

      <template #buttons>
        <router-link :to="{ name: 'create-case-report', query: { step: 1 } }">
          <a-button type="primary"> <PlusOutlined /> Add New Report </a-button>
        </router-link>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <template #tabBarExtraContent>
        <show-hide-column v-model="columns" />
      </template>

      <a-table
        :columns="columns.filter((e) => e.show ?? true)"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #date="{ text }">
          {{ $customDate.mdy(text) }}
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{ name: 'show-case-report', params: { uuid: record.uuid } }"
            >
              <a-tooltip title="View">
                <a-button
                  type="link"
                  size="small"
                  @click="
                    $comman.caseReportLogStore({
                      id: record.id,
                      action: 'View Record',
                    })
                  "
                >
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <router-link
              v-if="![3, 4, 5].includes(record.status)"
              :to="{
                name: 'create-case-report',
                query: { uuid: record.uuid },
              }"
            >
              <a-tooltip title="Edit">
                <a-button
                  type="link"
                  size="small"
                  @click="
                    $comman.caseReportLogStore({
                      id: record.id,
                      action: 'Edit Record',
                    })
                  "
                >
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <router-link
              v-if="showLiveChartingButton(record)"
              :to="{
                name: 'case-report-live-charting',
                params: { uuid: record.uuid },
              }"
            >
              <a-tooltip title="Live Charting">
                <a-button type="link" size="small">
                  <i class="ti ti-device-heart-monitor ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <!-- v-if="![3, 4, 5].includes(record.status)" -->
            <a-popconfirm
              v-if="
                ![3, 4, 5].includes(record.status) &&
                !itHasShiftChangeEvent(record)
              "
              title="Are you sure you want to delete this case report?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="
                () => {
                  $comman.caseReportLogStore({
                    id: record.id,
                    action: 'Delete Record',
                  });
                  handleStatusChangeEvent({ id: record.id, status: '0' });
                }
              "
            >
              <a-tooltip title="Delete" placement="bottom">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>

            <template v-if="record.status != 1">
              <a-tooltip title="Download">
                <a-button type="link" size="small" @click="downloadPdf(record)">
                  <i class="ti ti-download ti-lg"></i>
                </a-button>
              </a-tooltip>

              <a-tooltip title="Print">
                <a-button type="link" size="small" @click="renderPdf(record)">
                  <i class="ti ti-printer ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>

            <template v-if="record.status == 2">
              <a-tooltip title="Start New Procedure">
                <a-button type="link" size="small" @click="copyRecord(record)">
                  <i class="ti ti-copy ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import showHideColumn from "../../components/table/showHideColumn.vue";
import datatableMixins from "../../mixins/datatableMixins";

export default {
  components: {
    PlusOutlined,
    showHideColumn,
  },

  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.caseReportDatatableForEmployeeUrl,
      statusChangeUrl: this.$constants.caseReportChangeStatusUrl,
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: true,
          slots: {
            customRender: "date",
          },
          defaultSortOrder:
            this.$route.query?.sortField == "date"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Report No",
          dataIndex: "report_no",
          key: "report_no",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.report_no
            ? [this.$route.query?.report_no]
            : undefined,
        },
        {
          title: "Facility",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "MR No",
          dataIndex: "mr_number",
          key: "mr_number",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.mr_number
            ? [this.$route.query?.mr_number]
            : undefined,
        },
        {
          title: "Created By",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_name
            ? [this.$route.query?.employee_name]
            : undefined,
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          customRender: ({ text }) => this.$customDate.ll(text),
          defaultSortOrder: this.$route.query?.sortField
            ? this.$route.query?.sortField == "created_at"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
          show: false,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.caseReportStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        report_no: this.$route.query?.report_no
          ? [this.$route.query?.report_no]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        mr_number: this.$route.query?.mr_number
          ? [this.$route.query?.mr_number]
          : undefined,
        employee_name: this.$route.query?.employee_name
          ? [this.$route.query?.employee_name]
          : undefined,
        sortField: this.$route.query?.sortField ?? "created_at",
        sortOrder: this.$route.query?.sortOrder ?? "descend",
      },
    };
  },

  methods: {
    refreshDatatable() {
      this.$refs.datatable.refresh();
    },

    action(val) {
      commonService
        .store(this.$constants.caseReportChangeStatusUrl, val)
        .then((res) => {
          if (res.success) {
            this.refreshDatatable();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },

    downloadPdf(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "PDF Download",
      });
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.caseReportExportPdfUrl,
          { id: record.id },
          `${record.report_no}_case_report.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    renderPdf(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "Render Download",
      });
      this.datatableLoading = true;

      commonService
        .renderFile(
          this.$constants.caseReportExportPdfUrl,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    showLiveChartingButton(record) {
      if (record.hospital.is_live_charting_enable != 1) {
        return false;
      }
      return this.$constants.caseServicesIdForCPB
        .concat(this.$constants.caseServicesIdForATS)
        .concat(this.$constants.caseServicesIdForHIPEC)
        .concat(this.$constants.caseServicesIdForECMO)
        .concat(this.$constants.caseServicesIdForNRP)
        .filter((value, index, array) => array.indexOf(value) === index)
        .reduce(
          (bool, curr) => bool || record.data.services.includes(curr),
          false
        );
    },

    itHasShiftChangeEvent(record) {
      let bool = false;
      if (record.live_chart) {
        bool =
          bool ||
          record.live_chart?.atx?.data?.filter((e) => e.event == "Shift Change")
            ?.length > 0 ||
          record.live_chart?.cpb?.data?.filter((e) => e.event == "Shift Change")
            ?.length > 0 ||
          record.live_chart?.hipec?.data?.filter(
            (e) => e.event == "Shift Change"
          )?.length > 0 ||
          record.live_chart?.ecmo?.data?.filter(
            (e) => e.event == "Shift Change"
          )?.length > 0 ||
          record.live_chart?.nrp?.data?.filter((e) => e.event == "Shift Change")
            ?.length > 0;
      }

      return bool;
    },

    encrypt(o, salt) {
      o = JSON.stringify(o).split("");
      for (var i = 0, l = o.length; i < l; i++)
        if (o[i] == "{") o[i] = "}";
        else if (o[i] == "}") o[i] = "{";
      return encodeURI(salt + o.join(""));
    },

    copyRecord(record) {
      let data = {
        date: moment(),
        hospital_id: record.hospital_id,
        mr_number: record.mr_number,
        account_number: record.data?.account_number,
        patient_first_name: record.patient_first_name,
        patient_last_name: record.patient_last_name,
        allergies: record.data?.allergies,
        height: record.data?.height,
        weight: record.data?.weight,
        body_surface_area: record.data?.body_surface_area,
        blood_type: record.data?.blood_type,
        dob: record.data?.dob,
        gender: record.data?.gender,
      };
      localStorage.setItem("copy-record", JSON.stringify(data));
      this.$router.push({
        name: "create-case-report",
        query: { info: "copy-record" },
      });
    },
  },
};
</script>
