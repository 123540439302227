<template>
    <a-descriptions
      size="small"
      :column="1"
      bordered
      :labelStyle="{ width: '200px' }"
      class="mb-sm-2"
    >
      <a-descriptions-item label="Employee Name">
        {{ record.full_name ??  "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Type">
        {{ record.type ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Attachment">
          <a-typography-link :href="record.attachment_url" target="_blank">
            {{ record.attachment }}
          </a-typography-link>
      </a-descriptions-item>
    </a-descriptions>

    <a-form :model="formState" layout="vertical" @finish="onSubmit">
      <a-form-item
        name="remarks"
        label="Remarks"
        :rules="{ required: true, message: 'This field is required' }"
      >
        <a-textarea
          v-model:value="formState.remarks"
          placeholder="Enter here..."
        />
      </a-form-item>

      <side-drawer-buttons name="Submit" />
    </a-form>
  </template>

  <script>
  import { commonService } from "@/services";
  import { mapActions, mapGetters } from "vuex";

  export default {
    data() {
      return {
        formState: {
          remarks: null,
        },
      };
    },

    emits: ["callback"],

    computed: {
      ...mapGetters("drawer", ["record"]),
    },
    methods: {
      ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

      onSubmit() {
        this.loadingStart();
          commonService
          .store(this.$constants.credentialApprovalsssndlApprovalUrl, {
            employee_id: this.record.id,
            type : this.record.type,
            approval_status: 2,
            remarks: this.formState.remarks,
          })
          .then((res) => {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => this.loadingStop());
        }
      },
  };
  </script>
