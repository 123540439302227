<template>
  <a-form-item
    label="Additional Procedural Notes"
    name="additional_procedural_notes"
  >
    <a-textarea v-model:value="model.additional_procedural_notes" />
  </a-form-item>

  <a-form-item
    v-if="$comman.nrpServiceSelectedWithOrganHospital(model.services, hospital)"
    label="Surgical Notes"
    name="additional_surgical_notes"
  >
    <a-textarea v-model:value="model.additional_surgical_notes" />
  </a-form-item>

  <a-form-item label="Attachments" name="attachments">
    <a-upload-dragger
      :fileList="model.attachments"
      :multiple="true"
      :remove="handleRemoveReceipt"
      :before-upload="beforeUploadReceipt"
      accept="image/*,video/*,application/pdf"
    >
      <p class="ant-upload-drag-icon">
        <inbox-outlined></inbox-outlined>
      </p>
      <p class="ant-upload-text">
        Drag & drop files to upload or Click to select file.
      </p>
    </a-upload-dragger>
  </a-form-item>

  <a-form-item
    v-if="hospital.is_live_charting_enable == 2"
    label="Third-Party Live Charting Attachments"
    name="third_party_attachments"
  >
    <a-upload-dragger
      :fileList="model.third_party_attachments"
      :multiple="true"
      :remove="handleRemoveReceiptForThirdParty"
      :before-upload="beforeUploadReceiptForThirdParty"
      accept="image/*,video/*,application/pdf"
    >
      <p class="ant-upload-drag-icon">
        <inbox-outlined></inbox-outlined>
      </p>
      <p class="ant-upload-text">
        Drag & drop files to upload or Click to select file.
      </p>
    </a-upload-dragger>
  </a-form-item>

  <template
    v-if="!isDisposableOnlyCase && hospital.is_additional_time_charges_enable"
  >
    <a-divider orientation="left" class="mt-sm-0"> Operator Times </a-divider>

    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="cati_arrival_date"
          label="Arrival Date"
          name="cati_arrival_date"
          :rules="{
            type: 'date',
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-date-picker
            v-model:value="model.cati_arrival_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            @change="$emit('calculateCatiTotalTime')"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="cati_arrival_time"
          label="Arrival Time"
          name="cati_arrival_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeFormat,
          }"
        >
          <a-input
            v-model:value="model.cati_arrival_time"
            v-maska="$constants.timeMaska"
            placeholder="HH:mm"
            @change="$emit('calculateCatiTotalTime')"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="cati_departure_date"
          label="Departure Date"
          name="cati_departure_date"
          :rules="{
            type: 'date',
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-date-picker
            v-model:value="model.cati_departure_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            @change="$emit('calculateCatiTotalTime')"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="cati_departure_time"
          label="Departure Time"
          name="cati_departure_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeFormat,
          }"
        >
          <a-input
            v-model:value="model.cati_departure_time"
            v-maska="$constants.timeMaska"
            placeholder="HH:mm"
            @change="$emit('calculateCatiTotalTime')"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item name="cati_total_time">
          <template #label> Total Time </template>
          <a-input v-model:value="model.cati_total_time" :disabled="true" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          label="Additional Time Charge"
          name="additional_time_charge"
          :rules="{
            message: 'This field must be numeric!',
            type: 'number',
            validator: $validation.decimal,
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.additional_time_charge"
            placeholder="Amount in Dollars"
            :min="0"
            :step="0.01"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </template>

  <a-row type="flex" justify="center" class="mt-sm-2">
    <a-space direction="vertical">
      <vue-signature-pad
        :options="{ onEnd: save }"
        ref="signaturePad"
        :customStyle="$constants.signPadCustomCss"
      />

      <a-row type="flex" justify="center">
        <a-col>
          <a-typography-paragraph>
            Employee signature indicates that the information reported is
            complete and accurate.
          </a-typography-paragraph>
        </a-col>
      </a-row>
      <a-row v-if="model.sign" justify="center">
        <a-space>
          <a-button
            htmlType="button"
            type="primary"
            danger
            ghost
            @click="clear"
          >
            Clear
          </a-button>
        </a-space>
      </a-row>
    </a-space>
  </a-row>

  <a-col v-if="model?.extra_sign?.is_added == false" :span="12">
    <a-button type="primary" @click="addMoreSignature">
      <a-space>
        <i class="ti ti-plus ti-lg"></i>
        Add More Signature
      </a-space>
    </a-button>
  </a-col>

  <template v-if="model.extra_sign.is_added">
    <a-divider orientation="left"> Additional Signature </a-divider>
    <a-row :gutter="[16, 0]">
      <a-col :span="8">
        <a-form-item
          label="First Name"
          :name="['extra_sign', 'first_name']"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-input
            v-model:value="model.extra_sign.first_name"
            placeholder="Enter first name here..."
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          label="Last Name"
          :name="['extra_sign', 'last_name']"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-input
            v-model:value="model.extra_sign.last_name"
            placeholder="Enter last name here..."
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          label="Title"
          :name="['extra_sign', 'title']"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-input
            v-model:value="model.extra_sign.title"
            placeholder="Enter title here..."
          />
        </a-form-item>
      </a-col>
    </a-row>
  </template>

  <a-row
    v-show="model.extra_sign.is_added"
    type="flex"
    justify="center"
    class="mt-sm-2"
  >
    <a-space direction="vertical">
      <vue-signature-pad
        :options="{ onEnd: extraSignSave }"
        ref="extra_sign_pad"
        :customStyle="$constants.signPadCustomCss"
      />

      <a-row type="flex" justify="center">
        <a-col>
          <a-typography-paragraph>
            Signature above indicates that the information reported is complete
            and accurate.
          </a-typography-paragraph>
        </a-col>
      </a-row>
      <a-row justify="center">
        <a-space>
          <a-button
            v-if="model.extra_sign.sign"
            htmlType="button"
            type="primary"
            danger
            ghost
            @click="extraSignClear"
          >
            Clear
          </a-button>

          <a-button type="primary" danger @click="removeExtraSignature">
            <a-space> Remove Signature </a-space>
          </a-button>
        </a-space>
      </a-row>
    </a-space>
  </a-row>
</template>

<script>
import { InboxOutlined } from "@ant-design/icons-vue";

export default {
  name: "case-report-step-10",
  inheritAttrs: false,
  components: { InboxOutlined },
  props: [
    "step",
    "model",
    "hospital",
    "isCatiInvoiceAmountShow",
    "isDisposableOnlyCase",
  ],
  emits: ["validate", "calculateCatiTotalTime"],

  data() {
    return {
      //
    };
  },

  mounted() {
    this.model.attachments = this.model.attachments ?? [];
    if (!this.isShow) {
      this.$refs.signaturePad.fromDataURL(this.model.sign);
      if (this.model.extra_sign.is_added) {
        this.$refs.extra_sign_pad.fromDataURL(this.model.extra_sign.sign);
      }
    }
  },

  methods: {
    handleRemoveReceipt(file) {
      const index = this.model.attachments.indexOf(file);
      const newFileList = this.model.attachments.slice();
      newFileList.splice(index, 1);
      this.model.attachments = newFileList;
    },

    beforeUploadReceipt(file) {
      if (file.size < 15 * 1024 * 1024) {
        this.model.attachments = [...this.model.attachments, file];
      } else {
        this.$message.error(`${file.name} size is more then 15MB`);
      }
      return false;
    },

    handleRemoveReceiptForThirdParty(file) {
      const index = this.model.third_party_attachments.indexOf(file);
      const newFileList = this.model.third_party_attachments.slice();
      newFileList.splice(index, 1);
      this.model.third_party_attachments = newFileList;
    },

    beforeUploadReceiptForThirdParty(file) {
      if (file.size < 15 * 1024 * 1024) {
        this.model.third_party_attachments = [
          ...this.model.third_party_attachments,
          file,
        ];
      } else {
        this.$message.error(`${file.name} size is more then 15MB`);
      }
      return false;
    },

    clear() {
      this.model.sign = null;
      this.model.signed_at = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.sign = data;
      this.model.signed_at = this.$customDate.mdyhis(this.$comman.momentTz());
      return Promise.resolve();
    },

    addMoreSignature() {
      this.model.extra_sign.is_added = true;
      this.$nextTick(() => {
        this.$refs.extra_sign_pad.resizeCanvas();
      });
    },

    removeExtraSignature() {
      this.model.extra_sign = {
        first_name: null,
        last_name: null,
        title: null,
        sign: null,
        signed_at: null,
        is_added: false,
      };
      this.extraSignClear();
    },

    extraSignClear() {
      this.model.extra_sign.sign = null;
      this.$refs.extra_sign_pad.clearSignature();
    },

    extraSignSave() {
      const { isEmpty, data } = this.$refs.extra_sign_pad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.extra_sign.sign = data;
      this.model.extra_sign.signed_at = this.$customDate.mdyhis(
        this.$comman.momentTz()
      );
      return Promise.resolve();
    },
  },
};
</script>
