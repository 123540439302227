<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #title>
        Case Reporting Details
        {{ record?.report_no ? `(${record.report_no})` : "" }}
      </template>

      <template #extra>
        <a-space>
          <template v-if="record?.status == 2">
            <a-popconfirm
              v-if="record?.is_flagged == 0"
              title="Are you sure you want to flag this case report?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="flagCaseReport"
            >
              <a-button :loading="marking" danger>
                <a-space>
                  <i class="ti ti-flag ti-lg"></i>
                  Mark as Flagged
                </a-space>
              </a-button>
            </a-popconfirm>

            <a-popconfirm
              v-else
              title="Are you sure you want to unflag this case report?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="unflagCaseReport"
            >
              <a-button :loading="marking" danger>
                <a-space>
                  <i class="ti ti-flag-off ti-lg"></i>
                  Mark as Unflagged
                </a-space>
              </a-button>
            </a-popconfirm>
          </template>

          <a-popconfirm
            v-if="record?.status == 2"
            title="Are you sure you want to approve this case report?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleStatusChangeEvent({ id: record.id, status: '3' })"
          >
            <a-button type="primary" :loading="approving">
              <a-space>
                <i class="ti ti-status-change ti-lg"></i> Approve
              </a-space>
            </a-button>
          </a-popconfirm>

          <a-button type="primary" @click="openFTPUploadLogsDrawer">
            FTP Upload Logs
          </a-button>
        </a-space>
      </template>
    </a-page-header>

    <a-card size="small">
      <a-skeleton :loading="cardLoading">
        <component
          v-if="is"
          :is="is"
          v-bind="{
            model,
            reportNo,
            hospitals,
            caseServices,
            isCatiInvoiceAmountShow,
            isShow: true,
            record,
            hospital,
          }"
        >
          <router-view />
          <template v-if="atx" #atsLiveCharting>
            <atx-live-charting :atx="atx" />
          </template>

          <template v-if="cpb" #cpbLiveCharting>
            <cpb-live-charting :cpb="cpb" :record="record" />
          </template>

          <template v-if="hipec" #hipecLiveCharting>
            <hipec-live-charting :hipec="hipec" />
          </template>

          <template v-if="ecmo" #ecmoLiveCharting>
            <ecmo-live-charting :ecmo="ecmo" />
          </template>

          <template v-if="nrp" #nrpLiveCharting>
            <nrp-live-charting :nrp="nrp" :record="record" />
          </template>
        </component>
      </a-skeleton>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { route } from "../../../helper/route";
import { commonService } from "../../../services";
import atxLiveCharting from "../../newCaseReport/liveCharting/atx.vue";
import cpbLiveCharting from "../../newCaseReport/liveCharting/cpb.vue";
import ecmoLiveCharting from "../../newCaseReport/liveCharting/ecmo.vue";
import hipecLiveCharting from "../../newCaseReport/liveCharting/hipec.vue";
import nrpLiveCharting from "../../newCaseReport/liveCharting/nrp.vue";

export default {
  components: {
    atxLiveCharting,
    cpbLiveCharting,
    hipecLiveCharting,
    ecmoLiveCharting,
    nrpLiveCharting,
  },

  data() {
    return {
      statusChangeUrl: this.$constants.caseReportChangeStatusUrl,
      approving: false,

      cardLoading: true,
      marking: false,
      submitBtnLoader: false,
      uuid: this.$route?.params?.uuid ?? null,
      record: null,
      reportNo: null,
      model: {
        // Basic Information
        date: null,
        hospital_id: undefined,
        mr_number: null,
        patient_first_name: null,
        patient_last_name: null,
        allergies: null,
        height: null,
        weight: null,
        body_surface_area: null,

        // Operative Data
        surgeon: null,
        anesthesia_provider: null,
        procedure: null,
        case_status: undefined,
        services: [],
        service_category: undefined,
        reop: undefined,
        coronary_artery_bypass: undefined,
        cab_technique: undefined,
        aortic: undefined,
        mitral: undefined,
        tricuspid: undefined,
        pulmonic: undefined,
        other_cardiac_procedure: undefined,
        cardiac_procedure: undefined,
        other_non_cardiac_procedure: undefined,
        non_cardiac_procedure: undefined,
        patient_in_room_time: null,
        surgery_start_time: null,
        surgery_end_time: null,
        patient_out_of_room_time: null,
        start_date_of_ecmo_run: null,
        start_time_of_ecmo_run: null,
        end_date_of_ecmo_run: null,
        end_time_of_ecmo_run: null,
        cpb_time: null,
        cross_clamp_time: null,
        circulatory_arrest_time: null,
        cati_invoice_amount: null,
        total_ecmo_run_time: null,
        cati_invoice_amount: null,

        // Disposible Products
        products: [],

        // Quality Indicators
        first_blood_glucose_level: null,
        last_blood_glucose_level: null,
        anesthesia_volumn_pre_bypass: null,
        net_prime_volume: null,
        ultrafiltration_on_cpb: null,
        modified_ultrafiltration: null,
        act_seconds_prior_to_initiation: null,
        act_seconds_while_on_cpb: null,
        lowest_act_on_cpb: null,
        post_induction_hematocrit: null,
        first_cpb_hematocrit: null,
        percent_decrease_in_hemtocrit: null,
        lowest_cpb_hematocrit: null,
        last_cpb_hematocrit: null,
        last_in_room_hematocrit: null,
        lowest_arterian_pressure_on_cpb: null,
        lowest_svo2_on_cpb: null,
        lowest_cpb_base_excess_deficit: null,
        highest_blood_glucose_level: null,
        highest_arterial_blood_temperature: null,
        lowest_core_body_temperature: null,
        minimum_cardiac_index_during_cpb: null,
        prbc_transfusion_during_cpb: null,
        additional_crystalloid_volume_during_cpb: null,
        estimated_blood_volume: null,
        predicted_cpb_hematocrit: null,
        diff_predicted_cpb_hct_vs_actual_cpb_hct: null,

        // Cell Saver Operative Data
        washed_rbcs_reinfused: null,
        type_of_anticoagulant_used: null,
        total_processed_volumn: null,
        anticoagulant_volumn_used: null,
        wash_volumn_used: null,
        estimated_blood_loss: null,
        clear_effluent_wash_line: null,
        autologous_blood_transfused_within_4_hours: null,
        cell_saver_hct: null,
        was_a_qc_sample_obtained_for_this_procedure: null,
        sources: [],

        // Procedure Info
        is_sts_data: undefined,
        endoscopic_vein_harvest_time: null,
        radial_artery_harvest_time: null,
        proximal_techniques: [],
        cardioplegia_types: [],
        coronary_bypasses: [],

        // HIPEC Quality Indicators
        cytotoxic_drug_type_and_dose: null,
        prime_type_and_amount: null,
        average_inflow_temperature: null,
        average_outflow_temperature: null,
        pump_flow_rate: null,
        average_reservoir_volume: null,
        additional_comments: null,

        // Post Procedure Maintenance
        is_heart_lung: undefined,
        heart_lung_device: undefined,
        is_heater_cooler: undefined,
        heater_cooler_device: undefined,
        is_cell_saver: undefined,
        cell_saver_device: undefined,

        // Preview
        sign: null,

        // File Upload Or Capture Image
        attachments: [],
        third_party_attachments: [],
      },

      hospitals: [],
      caseServices: [],
    };
  },

  computed: {
    is() {
      return route(`newCaseReport/steps/step9`);
    },

    hospital() {
      return this.record?.hospital;
    },

    isShowAtxTab() {
      return this.$constants.caseServicesIdForATS.reduce(
        (bool, curr) =>
          bool || (this.record?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowCpbTab() {
      return this.$constants.caseServicesIdForCPB.reduce(
        (bool, curr) =>
          bool || (this.record?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowHipecTab() {
      return this.$constants.caseServicesIdForHIPEC.reduce(
        (bool, curr) =>
          bool || (this.record?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowEcmoTab() {
      return this.$constants.caseServicesIdForECMO.reduce(
        (bool, curr) =>
          bool || (this.record?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowNRPTab() {
      return this.$constants.caseServicesIdForNRP.reduce(
        (bool, curr) =>
          bool || (this.record?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    atx() {
      return this.isShowAtxTab && this.record?.live_chart?.atx
        ? this.record.live_chart.atx
        : null;
    },

    cpb() {
      return this.isShowCpbTab && this.record?.live_chart?.cpb
        ? this.record.live_chart.cpb
        : false;
    },

    hipec() {
      return this.isShowHipecTab && this.record?.live_chart?.hipec
        ? this.record.live_chart.hipec
        : false;
    },

    ecmo() {
      return this.isShowEcmoTab && this.record?.live_chart?.ecmo
        ? this.record.live_chart.ecmo
        : false;
    },

    nrp() {
      return this.isShowNRPTab && this.record?.live_chart?.nrp
        ? this.record.live_chart.nrp
        : false;
    },

    isCatiInvoiceAmountShow() {
      if (this.record?.hospital) {
        return this.record.hospital.companies[0].id == 2;
      }
      return false;
    },
  },

  async mounted() {
    await commonService
      .get(this.$constants.caseReportShowUrl, { uuid: this.uuid })
      .then((res) => {
        this.record = res.data;
        this.modifyModelData(res.data);
      })
      .catch((err) => {
        this.$message.error(err);
        return this.$router.go(-1);
      });

    await this.fetchCaseServices();
    this.cardLoading = false;
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    openFTPUploadLogsDrawer() {
      this.open({
        title: "FTP Upload Logs",
        path: "admin.newCaseReport.ftpUploadLogs",
        record: this.record,
      });
    },

    modifyModelData(report) {
      this.reportNo = report.report_no;
      this.uuid = report.uuid;
      let attachments = [];
      if (report.attachments.length > 0) {
        attachments = report.attachments.map((v) => ({
          id: v.id,
          uid: v.file_name,
          name: v.file_name_with_ext,
          url: v.full_file_path,
        }));
      }
      let third_party_attachments = [];
      if (report.third_party_attachments.length > 0) {
        third_party_attachments = report.third_party_attachments.map((v) => ({
          id: v.id,
          uid: v.file_name,
          name: v.file_name_with_ext,
          url: v.full_file_path,
        }));
      }

      this.model = {
        ...report.data,
        date: moment(report.date),
        attachments,
        third_party_attachments,
        organs_considered: report?.data?.organs_considered
          ? report?.data?.organs_considered.split(", ")
          : [],
        organs_recovered: report?.data?.organs_recovered
          ? report?.data?.organs_recovered.split(", ")
          : [],
      };
    },

    fetchCaseServices() {
      if (this.model.hospital_id) {
        return commonService
          .get(this.$constants.getCaseServicesUrl, {
            hospital_id: this.model.hospital_id,
          })
          .then((res) => {
            this.caseServices = res.data.map((item) => ({
              text: item.name,
              value: item.id,
            }));
          });
      }
    },

    handleStatusChangeEvent(val) {
      this.approving = true;
      commonService
        .store(this.statusChangeUrl, val)
        .then((res) => {
          this.record.status = 3;
          this.$message.success(res.message);
          this.$router.replace({
            name: "admin.case-report",
            query: { status: 2 },
          });
        })
        .catch((err) => {
          this.$message.error(err ?? "Something went wrong.");
        })
        .finally(() => {
          this.approving = false;
        });
    },

    flagCaseReport() {
      this.marking = true;
      commonService
        .store(this.$constants.caseReportFlagUrl, {
          id: this.record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.record.is_flagged = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.marking = false;
        });
    },

    unflagCaseReport() {
      this.marking = true;
      commonService
        .store(this.$constants.caseReportUnflagUrl, {
          id: this.record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.record.is_flagged = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.marking = false;
        });
    },
  },
};
</script>
