<template>
  <a-card
    :loading="loading"
    title="Hospital Info"
    size="small"
    class="hospital-info"
  >
    <a-descriptions
      size="small"
      :column="{ lg: 1, md: 1, sm: 1, xs: 1 }"
      bordered
    >
      <a-descriptions-item label="Hospital Name">
        {{ hospital?.name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Company">
        <template v-for="(v, i) in hospital.companies" :key="i">
          {{ v.name }}
        </template>
      </a-descriptions-item>

      <a-descriptions-item label="Email">
        <a-typography-link
          v-if="hospital.email"
          :href="`mailto:${hospital.email}`"
        >
          {{ hospital.email }}
        </a-typography-link>

        <a-typography-paragraph v-else> N/A </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Phone">
        <a-typography-link
          v-if="hospital.contact_no"
          :href="`tel:${hospital.contact_no}`"
        >
          {{ hospital.contact_no ?? "N/A" }}
        </a-typography-link>

        <a-typography-paragraph v-else> N/A </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Fax Number">
        <a-typography-link
          v-if="hospital.fax_no"
          :href="`tel:${hospital.fax_no}`"
        >
          {{ hospital.fax_no ?? "N/A" }}
        </a-typography-link>

        <a-typography-paragraph v-else> N/A </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Website">
        <a-typography-link
          v-if="hospital.website"
          :href="hospital.website"
          target="_blank"
        >
          {{ hospital.website }}
        </a-typography-link>

        <a-typography-paragraph v-else> N/A </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Address">
        {{ hospital.address_line_1 ? `${hospital.address_line_1},` : "" }}
        {{ hospital.address_line_2 ? `${hospital.address_line_2},` : "" }}
        {{ hospital.city ? `${hospital.city},` : "" }}
        {{ hospital.state ? `${hospital.state},` : "" }}
        {{ hospital.zipcode ? `${hospital.zipcode}` : "" }}
      </a-descriptions-item>

      <a-descriptions-item label="Shipping Name">
        {{
          hospital.is_same_as_facility_address == 1
            ? hospital.name
            : hospital.shipping_name
        }}
      </a-descriptions-item>

      <a-descriptions-item label="Shipping Address">
        <template v-if="hospital.is_same_as_facility_address == 1">
          {{ hospital.address_line_1 ? `${hospital.address_line_1},` : "" }}
          {{ hospital.address_line_2 ? `${hospital.address_line_2},` : "" }}
        </template>
        <template v-else>
          {{
            hospital.shipping_address_line_1
              ? `${hospital.shipping_address_line_1},`
              : ""
          }}
          {{
            hospital.shipping_address_line_2
              ? `${hospital.shipping_address_line_2},`
              : ""
          }}
        </template>
        {{ hospital.city ? `${hospital.city},` : "" }}
        {{ hospital.state ? `${hospital.state},` : "" }}
        {{ hospital.zipcode ? `${hospital.zipcode}` : "" }}
      </a-descriptions-item>

      <a-descriptions-item>
        <template #label>
          Account Number
          <a-tooltip>
            <template #title>
              This is intended for entering a specific account number that is
              associated with a manufacturer and is linked to a particular
              facility.
            </template>
            <a-button type="link" size="small">
              <i class="ti ti-info-circle ti-lg"></i>
            </a-button>
          </a-tooltip>
        </template>
        <a-typography-paragraph>
          {{ hospital.delivery_code ?? "N/A" }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item
        label="Is travel compensation available for hospital?"
      >
        <a-typography-paragraph>
          {{ hospital.is_travel_comp_available == 1 ? "Yes" : "No" }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item
        label="Does this hospital has clinical site information?"
      >
        <a-typography-paragraph>
          {{ hospital.has_clinical_site == 1 ? "Yes" : "No" }}
          <span v-if="hospital.has_clinical_site == 1">
            |
            <a-typography-link
              v-if="hospital.google_docs_link"
              :href="hospital.google_docs_link"
              target="_blank"
            >
              {{ hospital.google_docs_link }}
            </a-typography-link>
          </span>
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="ADP Cost Number">
        <a-typography-paragraph>
          {{ adpCostNumber }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Quickbooks Class">
        <a-typography-paragraph>
          {{ quickbooksClassName }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Quickbooks Income Account">
        <a-typography-paragraph>
          {{ quickbooksIncomeAccountName }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Quickbooks Customer">
        <a-typography-paragraph>
          {{ quickbooksCustomerName }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item
        label="Does this facility utilize the live charting function?"
      >
        <a-typography-paragraph>
          {{
            $comman.getValueFromObject(
              hospital,
              "is_live_charting_enable",
              $constants.hospital_live_charting_enable_options,
              "label"
            )
          }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Does this facility require NRP?">
        <a-typography-paragraph>
          {{ hospital.is_can_receive_organs == 1 ? "Yes" : "No" }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item
        label="Does this facility allow product margin less than 10%?"
      >
        <a-typography-paragraph>
          {{
            hospital.has_allow_product_mergin_less_then_10_per == 1
              ? "Yes"
              : "No"
          }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="PO Number">
        <a-typography-paragraph>
          {{ hospital.po_no ?? "N/A" }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Attachment Password">
        <a-typography-paragraph>
          {{ hospital.attachment_password ?? "N/A" }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Notes">
        <a-typography-paragraph>
          <span v-html="$comman.getTextAreaValueAsHtml(hospital.notes)"></span>
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Timezone">
        <a-typography-paragraph>
          {{
            $comman.getValueFromObject(
              hospital,
              "timezone",
              $constants.timezones
            )
          }}
        </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Logo">
        <template v-if="hospital.logo">
          <img
            width="100"
            height="100"
            :src="hospital.logo_full_path"
            alt="hospital-logo"
          />
        </template>

        <template v-else> Not uploaded yet </template>
      </a-descriptions-item>
    </a-descriptions>
  </a-card>
</template>

<script>
export default {
  name: "hospital-overview",
  props: {
    hospital: {
      required: true,
      default: null,
    },
  },

  computed: {
    loading() {
      return this.hospital == null;
    },

    adpCostNumber() {
      return this.hospital.id.toString().padStart(3, "0");
    },

    quickbooksClassName() {
      if (this.hospital?.quickbooks_class_id) {
        let quickbookClass = JSON.parse(this.hospital.quickbooks_class_info);
        return quickbookClass?.Name || "N/A";
      }
      return "N/A";
    },

    quickbooksIncomeAccountName() {
      if (this.hospital?.quickbooks_income_account_id) {
        let quickbookIncomeAccount = JSON.parse(
          this.hospital.quickbooks_income_account_info
        );
        return quickbookIncomeAccount?.Name || "N/A";
      }
      return "N/A";
    },

    quickbooksCustomerName() {
      if (this.hospital?.quickbooks_customer_id) {
        let quickbookCustomer = JSON.parse(
          this.hospital.quickbooks_customer_info
        );
        return quickbookCustomer?.DisplayName || "N/A";
      }
      return "N/A";
    },
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>

<style scoped>
.hospital-info div.ant-typography,
.hospital-info .ant-typography p {
  margin-bottom: 0;
}
</style>
