<template>
  <div class="revenue-cagr-graph-component">
    <a-row :gutter="[16, 16]">
      <a-col :xs="16">
        <a-spin :spinning="loading">
          <a-card title="Yearly Revenue with CAGR" size="small">
            <div ref="yearlyRevenueWithCagrChart"></div>
          </a-card>
        </a-spin>
      </a-col>

      <a-col :xs="8">
        <a-spin :spinning="loading">
          <a-card
            :title="`Est. Revenue Projection (FY ${currentYear})`"
            size="small"
          >
            <ul class="statistic-list">
              <li
                class="statistic-item"
                v-for="(statistic, i) in statistics"
                :key="`${i}_statistic`"
              >
                <h4 class="statistic-title">{{ statistic.title }}</h4>
                <template v-if="currentYearTotalIncome == null">
                  <a-skeleton :paragraph="{ rows: 0 }" active />
                </template>
                <template v-else>
                  <span class="statistic-amount">{{ statistic.amount }}</span>
                  <span class="statistic-suffix">{{ statistic.suffix }}</span>
                </template>
              </li>
            </ul>
          </a-card>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { DualAxes } from "@antv/g2plot";
import moment from "moment";
import { commonService } from "../../../services";

export default {
  name: "revenue-cagr-graph",

  props: {
    totalIncome: {
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      data: [],
      chart: null,
      config: {
        xField: "year",
        yField: ["total", "cagr"],
        geometryOptions: [
          {
            geometry: "column",
            label: {
              position: "top",
              formatter: (v) => this.$comman.withCurrency(v.total),
            },
            color: ({ year }) => {
              return year === moment().format("YYYY") + "E"
                ? "#6294FA"
                : "orange";
            },
            tooltip: {
              formatter: (v) => ({
                name: "Amount",
                value: this.$comman.withCurrency(v.total),
              }),
            },
            label: {
              position: "top",
              formatter: (v) => this.$comman.abbreviatedNumber(v.total),
            },
          },
          {
            geometry: "line",
            lineStyle: {
              lineWidth: 2,
            },
            color: "red",
            tooltip: {
              formatter: (v) => ({
                name: "CAGR",
                value: `${v.cagr}%`,
              }),
            },
            label: {
              position: "top",
              formatter: (v) => `${v.cagr}%`,
            },
          },
        ],
        yAxis: [{}, { min: 1, max: 100 }],
        meta: {
          year: {
            alias: "Year",
          },
          total: {
            alias: "Total",
          },
          cagr: {
            alias: "CAGR",
          },
        },
        legend: {
          layout: "horizontal",
          position: "top",
        },
      },

      currentYearTotalIncome: null,
      currentYearTotalRevenueTarget: 0,
      currentYearCarolinaRevenue: 0,
      currentYear: moment().format("YYYY"),
    };
  },

  computed: {
    statistics() {
      return [
        {
          title: "Based on Last 5 Years of Avg CAGR",
          amount: this.$comman.abbreviatedNumber(
            this.finalAmountBaseOnLast5Years
          ),
          suffix: `(CAGR: ${this.avgCagr}%)`,
        },
        {
          title: "Based on Current Revenue Rate",
          amount: this.$comman.abbreviatedNumber(
            this.finalAmountBaseOnCurrentYears
          ),
          suffix: `(CAGR: ${this.currentYearCagrBaseOnIncome}%)`,
        },
        {
          title: "Based on Management Projection",
          amount: this.$comman.abbreviatedNumber(
            this.currentYearTotalRevenueTarget
          ),
          suffix: `(CAGR: ${this.currentYearCagrBaseOnTarget}%)`,
        },
      ];
    },

    avgCagr() {
      return this.data.length > 0
        ? this.$comman.average(this.data.map((e) => e.cagr))
        : 0;
    },

    lastYearTotalRevenue() {
      let amount = 0;
      if (this.data.length != 0) {
        amount = this.data[this.data.length - 1].total;
      }
      return amount;
    },

    finalAmountBaseOnLast5Years() {
      let amount = 0;
      if (this.data.length != 0) {
        amount = this.lastYearTotalRevenue * (this.avgCagr / 100 + 1);
      }
      return amount;
    },

    finalAmountBaseOnCurrentYears() {
      let amount = 0;
      if (this.currentYearTotalIncome) {
        let days = moment().diff(moment().startOf("year"), "days");
        let carolina = parseFloat(this.currentYearCarolinaRevenue);
        let keystone = parseFloat(
          ((this.currentYearTotalIncome + carolina) / days) * 365
        );

        amount = Number(parseFloat(keystone).toFixed(2));
      }
      return amount;
    },

    currentYearCagrBaseOnIncome() {
      let cagr = 0;
      if (this.currentYearTotalIncome) {
        cagr = Number(
          parseFloat(
            (this.finalAmountBaseOnCurrentYears / this.lastYearTotalRevenue -
              1) *
              100
          ).toFixed(2)
        );
      }
      return cagr;
    },

    currentYearCagrBaseOnTarget() {
      let cagr = 0;
      if (this.data.length != 0) {
        cagr = Number(
          parseFloat(
            (this.currentYearTotalRevenueTarget / this.lastYearTotalRevenue -
              1) *
              100
          ).toFixed(2)
        );
      }
      return cagr;
    },
  },

  mounted() {
    this.fetchSetting();
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.loading = true;
      commonService
        .get(this.$constants.financeCompanyrevenue)
        .then(async (res) => {
          this.data = res.data;
          let resData = res.data;
          resData.push({
            year: `${this.currentYear}E`,
            total: this.finalAmountBaseOnLast5Years,
            cagr: this.avgCagr,
          });
          let amounts = resData.map((e) => e.total);
          let maxAmount = amounts.length > 0 ? Math.max(...amounts) : 0;
          this.config.yAxis[0] = {
            max: Math.round(maxAmount + maxAmount * 0.2),
          };

          let cagr = resData.map((e) => e.cagr);
          let maxCagr = cagr.length > 0 ? Math.max(...cagr) : 0;
          let minCagr = cagr.length > 0 ? Math.min(...cagr) : 0;
          this.config.yAxis[1] = {
            max: Math.round(maxCagr + maxCagr * 0.1),
            min: Math.round(minCagr - minCagr * 0.2),
          };

          let obj = {
            data: [resData, resData],
            ...this.config,
          };
          if (this.chart) {
            this.chart.update(obj);
          } else {
            if (this.$refs["yearlyRevenueWithCagrChart"]) {
              this.chart = new DualAxes(
                this.$refs["yearlyRevenueWithCagrChart"],
                obj
              );
            }
            await this.$nextTick(() => {
              this.chart?.render();
            });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.loading = false));
    },

    fetchSetting() {
      this.loading = true;

      commonService
        .store(this.$constants.getSettingUrl, {
          key: [
            "current_year_revenue_projection",
            "current_year_carolina_revenue",
          ],
        })
        .then((res) => {
          this.currentYearTotalRevenueTarget =
            res.data.current_year_revenue_projection;
          this.currentYearCarolinaRevenue =
            res.data.current_year_carolina_revenue;
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  watch: {
    totalIncome(newVal) {
      if (this.currentYearTotalIncome == null) {
        this.currentYearTotalIncome = Number(newVal);
      }
    },
  },
};
</script>

<style lang="less">
.revenue-cagr-graph-component {
  .statistic-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    .statistic-item {
      padding: 10px 5px;
      .statistic-title {
        font-size: 16px;
        margin-bottom: 0;
      }
      .statistic-amount {
        font-size: 20px;
        font-weight: 500;
      }
      .statistic-suffix {
        font-size: 12px;
        color: gray;
        margin-left: 10px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #ececec;
      }

      .ant-skeleton {
        .ant-skeleton-content {
          .ant-skeleton-title {
            margin-top: 5px;
          }
          .ant-skeleton-paragraph {
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>
