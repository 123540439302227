<template>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Declaration of Death Date"
        name="dbdor_declaration_of_death_date"
        :rules="{
          message: 'Invalid date.',
          type: 'date',
        }"
      >
        <a-date-picker
          v-model:value="model.dbdor_declaration_of_death_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Declaration of Death Time"
        name="dbdor_declaration_of_death_time"
        :rules="{
          message: 'Invalid time.',
          validator: $validation.timeWithSecFormat,
        }"
      >
        <a-input
          v-model:value="model.dbdor_declaration_of_death_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Incision Date"
        name="dbdor_incision_date"
        :rules="{
          message: 'Invalid date.',
          type: 'date',
        }"
      >
        <a-date-picker
          v-model:value="model.dbdor_incision_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Incision Time"
        name="dbdor_incision_time"
        :rules="{
          message: 'Invalid time.',
          validator: $validation.timeWithSecFormat,
        }"
      >
        <a-input
          v-model:value="model.dbdor_incision_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Cross Clamp Date"
        name="dbdor_cross_clamp_date"
        :rules="{
          message: 'Invalid date.',
          type: 'date',
        }"
      >
        <a-date-picker
          v-model:value="model.dbdor_cross_clamp_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Cross Clamp Time"
        name="dbdor_cross_clamp_time"
        :rules="{
          message: 'Invalid time.',
          validator: $validation.timeWithSecFormat,
        }"
      >
        <a-input
          v-model:value="model.dbdor_cross_clamp_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Heparin Date"
        name="dbdor_heparin_date"
        :rules="{
          message: 'Invalid date.',
          type: 'date',
        }"
      >
        <a-date-picker
          v-model:value="model.dbdor_heparin_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Heparin Time"
        name="dbdor_heparin_time"
        :rules="{
          message: 'Invalid time.',
          validator: $validation.timeWithSecFormat,
        }"
      >
        <a-input
          v-model:value="model.dbdor_heparin_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        name="dbdor_heparin"
        :rules="{
          pattern: new RegExp(/^[0-9]*$/),
          message: 'This field must be valid input.',
        }"
      >
        <template #label> Heparin (kiu) </template>
        <a-input-number
          type="number"
          v-model:value="model.dbdor_heparin"
          placeholder="Enter heparin here..."
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Organ(s) Recovered"
        name="organs_recovered"
        :rules="{
          required: true,
          type: 'array',
          min: 1,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
        }"
      >
        <a-select
          v-model:value="model.organs_recovered"
          mode="multiple"
          style="width: 100%"
          :token-separators="[',']"
          placeholder="Organ(s) Recovered"
          :options="$constants.organs"
          @change="() => this.$emit('handleOrganRecoveredChange')"
        />
      </a-form-item>
    </a-col>
  </a-row>

  <a-row :gutter="[20, 0]">
    <template v-for="(dbdor_organ, key) in model.dbdor_organs" :key="key">
      <a-col :span="24">
        <a-divider orientation="left">{{ key }}</a-divider>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Start of Cold Flush Date"
          :name="['dbdor_organs', key, 'start_of_cold_flush_date']"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="dbdor_organ.start_of_cold_flush_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Start of Cold Flush Time"
          :name="['dbdor_organs', key, 'start_of_cold_flush_time']"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="dbdor_organ.start_of_cold_flush_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="End of Cold Flush Date"
          :name="['dbdor_organs', key, 'end_of_cold_flush_date']"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="dbdor_organ.end_of_cold_flush_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="End of Cold Flush Time"
          :name="['dbdor_organs', key, 'end_of_cold_flush_time']"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="dbdor_organ.end_of_cold_flush_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Out of Body Date"
          :name="['dbdor_organs', key, 'out_of_body_date']"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="dbdor_organ.out_of_body_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Out of Body Time"
          :name="['dbdor_organs', key, 'out_of_body_time']"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="dbdor_organ.out_of_body_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>
    </template>
  </a-row>
</template>

<script>
export default {
  name: "organ-recovery-qi-indicator-step",
  inheritAttrs: false,
  props: ["step", "model"],
  emits: ["validate", "handleOrganRecoveredChange"],

  data() {
    return {
      //
    };
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>
