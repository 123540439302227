<template>
  <a-layout-content>
    <page-header>
      <template #title> Employees</template>

      <template #buttons>
        <a-space>
          <a-button
            @click="handleExportExcelEvent"
            :loading="datatableLoading"
            type="primary"
          >
            <a-space>
              <i class="ti ti-file-spreadsheet ti-lg"></i> Export Excel
            </a-space>
          </a-button>

          <!-- <router-link :to="{ name: 'create-employee' }">
            <a-button type="primary">
              <a-space> <i class="ti ti-plus ti-lg"></i> Add </a-space>
            </a-button>
          </router-link> -->

          <a-dropdown type="primary">
            <a-button><i class="ti ti-dots-vertical ti-lg"></i></a-button>

            <template #overlay>
              <a-menu>
                <!-- <a-menu-item>
                  <a-button
                    type="link"
                    @click="gustoEmployeeImport"
                    :loading="gustoImportLoading"
                  >
                    <a-space>
                      <i class="ti ti-refresh ti-1x"></i>
                      Sync with Gusto
                    </a-space>
                  </a-button>
                </a-menu-item> -->

                <a-menu-item>
                  <a-button
                    @click="quickbooksEmployeeSync"
                    :loading="quickbooksSyncLoading"
                    type="link"
                  >
                    <a-space>
                      <i class="ti ti-refresh ti-1x"></i>
                      Sync with Quickbooks
                    </a-space>
                  </a-button>
                </a-menu-item>

                <a-menu-item>
                  <a-button
                    @click="syncWithAdp"
                    :loading="syncWithAdpLoading"
                    type="link"
                  >
                    <a-space>
                      <i class="ti ti-refresh ti-1x"></i>
                      Sync with ADP
                    </a-space>
                  </a-button>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-space>
      </template>
    </page-header>

    <a-card
      @tabChange="(key) => (activeTabKey = key)"
      :activeTabKey="activeTabKey"
      :tabList="tabList"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <template #tabBarExtraContent>
        <show-hide-column v-model="columns" />
      </template>

      <a-table
        @change="handleTableChange"
        @refresh="refresh"
        :columns="columns.filter((e) => e.show ?? true)"
        :dataSource="dataSource"
        :loading="datatableLoading"
        :pagination="pagination"
        :rowKey="(record, index) => record.id"
        :scroll="scroll"
        size="small"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            @handleReset="handleDatatableReset"
            @handleSearch="handleDatatableSearch"
            :filterDropdown="filterDropdown"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #role_name="{ record }">
          <a-tag color="cyan">
            {{ role_name(record.user ? record.user.role_id : "N/A") }}
          </a-tag>
        </template>

        <template #employee_name="{ record }">
          <a-space>
            <a-tooltip
              :title="
                !!record.profile_status
                  ? 'All required credentials submitted.'
                  : 'Some of required credentials have expired or are missing.'
              "
            >
              <a-badge
                :status="!!record.profile_status ? 'success' : 'error'"
                dot
              />
            </a-tooltip>
            <span>{{ record.full_name }}</span>
          </a-space>
        </template>

        <template #employee_code="{ record }">
          <a-space>
            {{ record.employee_code }}
            <a-popover v-if="record.termination_date">
              <template #content>
                <div style="max-width: 300px">
                  <span style="font-weight: 500">Termination Date:</span>
                  {{ $customDate.mdy(record.termination_date) }}
                  <br />
                  <span style="font-weight: 500">Last Work Date:</span>
                  {{ $customDate.mdy(record.last_work_date) }}
                  <br />
                  <span style="font-weight: 500">Comment:</span>
                  {{ record.termination_comment }}
                </div>
              </template>
              <a-button size="small" type="link">
                <i class="ti ti-info-circle ti-lg"> </i>
              </a-button>
            </a-popover>
          </a-space>
        </template>

        <template #employment_type="{ record }">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'employment_type',
                $constants.employment_types,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "employment_type",
                $constants.employment_types
              )
            }}
          </a-tag>
        </template>

        <template #employee_type="{ record }">
          <a-tag
            :color="$comman.get_employee_type(record.employee_type, 'color')"
          >
            {{ $comman.get_employee_type(record.employee_type, "text") }}
          </a-tag>
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{
                name: 'show-employee',
                params: { id: record.id },
              }"
            >
              <a-tooltip title="View">
                <a-button size="small" type="link">
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <a-tooltip :title="record.rehire_eligible_indicator ? 'Rehire' : 'Not eligible for rehire'" v-if="record.status == 4">
            <template v-if="record.rehire_eligible_indicator">
              <a-button class="warning-color" type="link" @click="ReHiring(record)">
                <i class="ti ti-recycle ti-lg"></i>
              </a-button>
            </template>
            <template v-else>
              <a-button class="warning-color" type="link" disabled>
                <i class="ti ti-recycle ti-lg"></i>
              </a-button>
            </template>
          </a-tooltip>


            <template v-if="isAdmin && ![4, 5].includes(record.status)">
              <router-link
                :to="{
                  name: 'edit-employee',
                  params: { id: record.id },
                }" 
              >
                <a-tooltip title="Edit">
                  <a-button size="small" type="link">
                    <i class="ti ti-pencil ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>

              <a-tooltip
                v-if="record.status !== 0"
                placement="left"
                title="Send Credential Reminder Mail"
              >
                <a-button
                  @click="sendCredentialRemainderMail(record)"
                  size="small"
                  type="link"
                >
                  <i class="ti ti-certificate ti-lg"></i>
                </a-button>
              </a-tooltip>
              <a-popconfirm
                v-if="
                  [1, 3].includes(record.status) &&
                  record.id !== employee.id &&
                  hasFinanceAccess
                " 
                @confirm="() => loginToEmployeeAccount(record)"
                cancel-text="No"
                ok-text="Yes"
                ok-type="danger"
                title="Are you sure to login to this account? You will be logged out from your current account."
              >
                <a-tooltip title="Login to this account">
                  <a-button
                    :disabled="accountSwitching"
                    :loading="record.accountChangeLoading"
                    danger
                    size="small"
                    type="link"
                  >
                    <i class="ti ti-login ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
              <a-dropdown placement="bottomLeft">
                <a-button size="small" type="link">
                  <i class="ti ti-dots-vertical ti-lg"></i>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <template v-if="[1, 3].includes(record.status)">
                      <a-menu-item>
                        <a-tooltip placement="left" title="Send Welcome Email">
                          <a-button
                            @click="sendWelcomeEmail(record)"
                            size="small"
                            type="link"
                          >
                            <i class="ti ti-mail ti-lg"></i>
                          </a-button>
                        </a-tooltip>
                      </a-menu-item>
                      <a-menu-item>
                        <a-tooltip placement="left" title="Assign Role">
                          <a-button
                            @click="handleEmployeeRoleEditEvent(record)"
                            size="small"
                            type="link"
                          >
                            <i class="ti ti-subtask ti-lg"></i>
                          </a-button>
                        </a-tooltip>
                      </a-menu-item>
                      <a-menu-item>
                        <a-tooltip
                          placement="left"
                          title="Create Competency Assessment"
                        >
                          <a-button
                            @click="handleCreateCompetencyAssessment(record)"
                            size="small"
                            type="link"
                          >
                            <i class="ti ti-award ti-lg"></i>
                          </a-button>
                        </a-tooltip>
                      </a-menu-item>

                      <a-menu-item>
                        <a-tooltip placement="left" title="Reset Password">
                          <a-button
                            @click="resetPassword(record)"
                            size="small"
                            type="link"
                          >
                            <i class="ti ti-lock ti-lg"></i>
                          </a-button>
                        </a-tooltip>
                      </a-menu-item>

                      <a-menu-item v-if="record.id != 1">
                        <a-popconfirm
                          @confirm="
                            handleStatusChangeEvent({
                              id: record.id,
                              status: '0',
                            })
                          "
                          cancel-text="No"
                          ok-text="Yes"
                          title="Are you sure you want to inactive this employee?"
                        >
                          <a-tooltip placement="left" title="Inactive">
                            <a-button danger size="small" type="link">
                              <i class="ti ti-user-off ti-lg"></i>
                            </a-button>
                          </a-tooltip>
                        </a-popconfirm>
                      </a-menu-item>
                    </template>

                    <template v-else>
                      <a-menu-item>
                        <a-popconfirm
                          @confirm="
                            handleStatusChangeEvent({
                              id: record.id,
                              status: record?.user?.extra_rights == 99 ? 3 : 1,
                            })
                          "
                          cancel-text="No"
                          ok-text="Yes"
                          title="Are you sure you want to restore this employee?"
                        >
                          <a-tooltip placement="left" title="Restore">
                            <a-button size="small" type="link">
                              <i class="ti ti-rotate ti-lg"></i>
                            </a-button>
                          </a-tooltip>
                        </a-popconfirm>
                      </a-menu-item>
                    </template>

                    <a-menu-item
                      v-if="record.id != 1 && [0, 1].includes(record.status)"
                    >
                      <a-tooltip placement="left" title="Terminate">
                        <a-button
                          danger
                          size="small"
                          type="link"
                          @click="handleUserTerminateEvent(record)"
                        >
                          <i class="ti ti-user-x ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import showHideColumn from "../../components/table/showHideColumn.vue";
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  components: {
    showHideColumn,
  },

  data() {
    return {
      datatableUrl: this.$constants.employeeDatatableUrl,
      statusChangeUrl: this.$constants.employeeChangeStatusUrl,
      columns: [
        {
          title: "Name",
          dataIndex: "first_name",
          key: "first_name",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "employee_name",
          },
          defaultFilteredValue: this.$route.query?.first_name
            ? [this.$route.query?.first_name]
            : undefined,
          defaultSortOrder: this.$route.query?.sortField
            ? this.$route.query?.sortField == "first_name"
              ? this.$route.query.sortOrder
              : undefined
            : "ascend",
        },
        {
          title: "Employee Code",
          dataIndex: "employee_code",
          key: "employee_code",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "employee_code",
          },
          defaultFilteredValue: this.$route.query?.employee_code
            ? [this.$route.query?.employee_code]
            : undefined,
          defaultSortOrder:
            this.$route.query?.sortField == "employee_code"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Web Access",
          dataIndex: "role_name",
          key: "role_name",
          sorter: false,
          show: false,
          slots: {
            customRender: "role_name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.role_name
            ? [this.$route.query?.role_name]
            : undefined,
        },
        {
          title: "Employment Type",
          dataIndex: "employment_type",
          key: "employment_type",
          filters: this.$constants.employment_types,
          filterMultiple: false,
          slots: {
            customRender: "employment_type",
          },
          defaultFilteredValue: this.$route.query?.employment_type
            ? [parseInt(this.$route.query?.employment_type)]
            : undefined,
        },
        {
          title: "Profession Type",
          dataIndex: "employee_type",
          key: "employee_type",
          filters: this.$constants.employee_types,
          filterMultiple: false,
          slots: {
            customRender: "employee_type",
          },
          defaultFilteredValue: this.$route.query?.employee_type
            ? [parseInt(this.$route.query?.employee_type)]
            : undefined,
        },
        {
          title: "Last Login",
          dataIndex: "last_login_at",
          width: 200,
          customRender: ({ record }) =>
            this.$customDate.monthDYHIS(
              record?.successful_login_attempt?.created_at
            ),
        },

        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          customRender: ({ text }) => this.$customDate.ll(text),
          defaultSortOrder:
            this.$route.query?.sortField == "created_at"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.employeeStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        first_name: this.$route.query?.first_name
          ? [this.$route.query?.first_name]
          : undefined,
        employee_code: this.$route.query?.employee_code
          ? [this.$route.query?.employee_code]
          : undefined,
        role_name: this.$route.query?.role_name
          ? [this.$route.query?.role_name]
          : undefined,
        employment_type: this.$route.query?.employment_type
          ? [this.$route.query?.employment_type]
          : undefined,
        employee_type: this.$route.query?.employee_type
          ? [this.$route.query?.employee_type]
          : undefined,
        sortField: this.$route.query?.sortField
          ? this.$route.query.sortField
          : "first_name",
        sortOrder: this.$route.query?.sortOrder
          ? this.$route.query.sortOrder
          : "ascend",
      },
      gustoImportLoading: false,
      quickbooksSyncLoading: false,
      syncWithAdpLoading: false,
      roles: [],
      routes: [],
      accountSwitching: false,
      companies : []
    };
  },

  async mounted() {
    this.companies =await  this.$comman.getCompanies(); 

    Promise.all([this.$comman.getRoutes(), this.$comman.getRoles()]).then(
      (res) => {
        this.routes = res[0];
        this.roles = res[1];
      }
    ); 
  },
  computed: {
    ...mapGetters("auth", ["isAdmin", "employee", "hasFinanceAccess"]),
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    role_name(id) {
      let name = "N/A";
      if (this.roles.length > 0) {
        this.roles.forEach((role) => {
          if (role.id == id) {
            name = role.name;
          }
        });
      }
      return name;
    },

    loginToEmployeeAccount(employee) {
      const email = employee.email;
      employee.accountChangeLoading = true;
      this.accountSwitching = true;

      commonService
        .store(this.$constants.loginIntoOtherEmployeeAccountUrl, { email })
        .then(async (res) => {
          this.$message.success("Switching accounts...");
          localStorage.setItem("token", res.token.access_token);
          await this.$store.dispatch("init");
          // this.$router.push({ name: "dashboard" });
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          employee.accountChangeLoading = false;
          this.accountSwitching = false;
        });
    },

    ReHiring(record) {
      this.open({
        title: "Rehire Employee",
        path: "employee.drawerForm.reHiring",
        callback: this.refresh,
        record,
        extra: { companies: this.companies },
      });
    },

    handleEmployeeRoleEditEvent(record) {
      this.open({
        title: "Edit Employee Role and Rights",
        path: "employee.roleAndRights",
        callback: this.refresh,
        record: record,
        extra: { routes: this.routes, roles: this.roles },
      });
    },

    handleCreateCompetencyAssessment(record) {
      this.open({
        title: "Create Competency Assessment",
        path: "admin.competenceAssessments.store",
        callback: this.refresh,
        record: { employee_id: record.id },
        extra: { fromEmployees: true, employees: [record] },
      });
    },

    resetPassword(record) {
      this.open({
        title: "Reset Password",
        path: "employee.resetPassword",
        callback: this.refresh,
        record: record,
      });
    },

    handleUserTerminateEvent(record) {
      this.open({
        title: "Terminate Employee",
        path: "employee.drawerForm.terminate",
        callback: this.refresh,
        record,
      });
    },

    gustoEmployeeImport() {
      this.gustoImportLoading = true;
      commonService
        .store(this.$constants.importEmployeeFromGustoUrl)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.gustoImportLoading = false;
          this.refresh();
        });
    },

    quickbooksEmployeeSync() {
      this.quickbooksSyncLoading = true;
      commonService
        .store(this.$constants.syncVendorFromQuickbooksUrl)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.quickbooksSyncLoading = false;
          this.refresh();
        });
    },

    sendWelcomeEmail(record) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.employeeSendWelcomeEmailUrl, { id: record.id })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
          this.refresh();
        });
    },

    sendCredentialRemainderMail(record) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.employeeSendCredentialRemainderMailUrl, {
          id: record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
          this.refresh();
        });
    },

    handleExportExcelEvent() {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.employeeExportExcelUrl,
          { status: this.activeTabKey },
          "Employees.xlsx"
        )
        .then((res) => {
          this.$message.success(res);
          this.datatableLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.datatableLoading = false;
        });
    },

    syncWithAdp() {
      this.syncWithAdpLoading = true;
      commonService
        .store(this.$constants.adpSyncEmployeeUrl)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.syncWithAdpLoading = false;
          this.refresh();
        });
    },
  },
};
</script>
